import $ from 'jquery';
import prestashop from 'prestashop';

function getRequestData(op, quantity) {
  return {
    ajax: '1',
    qty: Math.abs(quantity),
    action: 'update',
    op: op
  }
}

(function () {
  let body = $('body');
  body.on('click', '.js-increase-product-quantity', function (e) {
    e.preventDefault();
    let url = $(this).attr('href');
    updateQuantity('up', url);
  });

  body.on('click', '.js-decrease-product-quantity', function (e) {
    e.preventDefault();
    let url = $(this).attr('href');
    updateQuantity('down', url);
  });

  body.on('change', '.qty-product-field', function () {
    let url = $(this).attr('data-update-url');
    let qty = parseInt($(this).val());
    let lastQty = $(this).attr('data-last-value');
    let operator = (lastQty > qty) ? 'down' : 'up';
    let lengthInc = 1;

    if (operator === 'down') {
      lengthInc = parseInt(lastQty) - qty;
    } else {
      lengthInc = qty - parseInt(lastQty);

      if ((parseInt(lengthInc) + parseInt(lastQty)) > 99) {
        $(this).val(lastQty);
        return false;
      }
    }

    updateQuantity(operator, url, lengthInc);
  })
})();

let timer;

function updateQuantity(op, url, qty = 1) {
  $.ajax({
    url: url,
    method: 'POST',
    data: getRequestData(op, qty),
    dataType: 'json',
    beforeSend: function (jqXHR) {
    }
  }).then(function (resp) {
    prestashop.emit('updateCart', {
      reason: resp
    });

    $('.cart-updated-message').fadeIn(400);

    clearTimeout(timer);
    timer = setTimeout(function () {
      $('.cart-updated-message').fadeOut(400);
    }, 3000);

  }).fail((resp) => {
    prestashop.emit('handleError', {
      eventType: 'updateProductInCart',
      resp: resp,
      cartAction: ''
    });
  });
}