import {BodyScroll} from './utils';

const searchWidget = $('#search_widget');
let searchURL = searchWidget.attr('data-search-controller-url');

const searchFormBox = $('.search-form-box');
const jsLiveSearch = $('.js-live-search');

const searchProductListContainer = $('#search-product-list-container');
const productListContainer = $('#product-list-container');
const productListNoResult = $('#product-list-no-result');

const jsOpenSearch = $('.js-open-search');
const jsCloseSearch = $('.js-close-search');
const jsResetResult = $('.js-reset-result');

export const closeSearch = () => {
  BodyScroll.unlock();
  searchWidget.fadeOut();
  resetResults(150);
};
jsCloseSearch.on('click', () => {
  closeSearch();
});

const openSearch = () => {
  BodyScroll.lock();
  searchWidget.fadeIn();
  jsLiveSearch.focus();
};
jsOpenSearch.on('click', () => {
  openSearch();
});

const resetResults = () => {
  jsLiveSearch.val('').focus();
  searchProductListContainer.fadeOut();
};
jsResetResult.on('click', () => {
  resetResults();
});

const liveSearch = () => {
  if (jsLiveSearch.val().length > 0) {
    searchProductListContainer.fadeOut();
  } else {
    searchProductListContainer.fadeIn();
  }
};
jsLiveSearch.on('keyup', () => {
  liveSearch();
  myEfficientFn(jsLiveSearch, searchURL)
});


function updateSearchProductListDOM(data) {
  if (!$(searchProductListContainer).is('visible')) {
    searchProductListContainer.show();
  }

  $('.js-search-qty').html(data.pagination.total_items);
  productListContainer.css('display', 'none');
  productListContainer.html(data.rendered_products);
  productListContainer.fadeIn();
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  }
}

function loadSearchBox(searchURL, jsLiveSearch) {
  $.post(searchURL, {
    s: jsLiveSearch,
    resultsPerPage: 999999
  }, null, 'json')
    .then(function (resp) {
      if (resp.products.length) {
        updateSearchProductListDOM(resp);
        productListNoResult.fadeOut();
        $('#product-list-header, #product-list-container').show();
      } else {
        if (!(searchProductListContainer.is('visible'))) {
          $('#search-product-list-container').show();
        }

        $('#product-list-header, #product-list-container').hide();
        productListNoResult.fadeIn()
      }
    })
    .fail(function () {
      alert("error");
    });
}

var myEfficientFn = debounce(function (jsLiveSearch, searchURL) {
  if ($(jsLiveSearch).val().length > 0) {
    loadSearchBox(searchURL, $(jsLiveSearch).val());
  } else {
    $('#product-list-header, #product-list-container, #product-list-no-result, #search-product-list-container').hide();
  }
}, 300);

searchFormBox.submit(function (e) {
  e.preventDefault();
  myEfficientFn(jsLiveSearch, searchURL);
});