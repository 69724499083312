/**
 * This is the entry point for specific javascript of theme
 */
import bsCustomFileInput from 'bs-custom-file-input';
/* A little plugin which makes Bootstrap 4 custom file input dynamic with no dependencies. */
bsCustomFileInput.init();

/* Bootstrap components from node_modules directory */
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/util';

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap-touchspin';

import prestashop from 'prestashop';

/* Helpers */
import './polyfill';
import './listeners';

/* Project Components */
import './shoppping-cart';
import './go-to-top';
import './header-top';
import './inputs';
import './filter';
import './increment-stepper';
import './listing';
import './sliders';
import './search';
import './cart';
