import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export const BodyScroll = {
  lock(selector = '[data-body-scroll-lock]') {
    document
      .querySelectorAll(selector)
      .forEach(elem => disableBodyScroll(elem));
  },

  unlock(selector = '[data-body-scroll-lock]') {
    document
      .querySelectorAll(selector)
      .forEach(elem => enableBodyScroll(elem));
  },

  flush() {
    clearAllBodyScrollLocks();
  },
};