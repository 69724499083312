import $ from 'jquery';

$(document).ready(function () {
  prestashop.blockcart = prestashop.blockcart || {};

  var showModal = prestashop.blockcart.showModal || function (modalContent) {
    var $body = $('body');
    var cartBlock = '#blockcart-modal';

    if ($('.modal-backdrop').length) {
      $('.modal-backdrop').remove();
    }

    if ($(cartBlock).length)
      $(cartBlock).remove();

    $body.append(modalContent);
    $(cartBlock).modal('show');
  };

  $(document).ready(function () {
    prestashop.on(
      'updateCart',
      function (event) {
        var refreshURL = $('.blockcart').data('refresh-url');
        var requestData = {};
        if (event && event.reason) {
          requestData = {
            id_product_attribute: event.reason.idProductAttribute,
            id_product: event.reason.idProduct,
            action: event.reason.linkAction
          };
        }
        $.post(refreshURL, requestData)
          .then(function (resp) {
          $('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
          if (resp.modal) {
            showModal(resp.modal);
          }
        }).fail(function (resp) {
          prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: resp});
        });
      }
    );
  });
});

$(document).on('click', '.remove-from-cart', function (e) {
  e.preventDefault();
  let linkToRemove = $(this).attr('href');

  $.ajax({
    url: linkToRemove,
    method: 'POST',
    data: {
      ajax: 1
    },
    dataType: 'html',
    beforeSend: function (jqXHR) {

    }
  }).then(function (resp) {
    prestashop.emit('updateCart', {
      reason: ''
    });

  }).fail((resp) => {
    prestashop.emit('handleError', {
      eventType: 'updateProductInCart',
      resp: resp,
      cartAction: linkToRemove
    });
  });
});



$('.js-add-to-cart').on('click', function (e) {
  e.preventDefault();
  e.stopPropagation();
  var id_product = $(this).data('idproduct');
  var id_attribute = $(this).data('idattribute');
  var qtyObject = $(this).parent().siblings('.cell-quantity').find('.qty-product-field');
  var qty = $(qtyObject).val();
  var actionURL = $('#fastActionUrl').data('actionlink');
  qtyObject.val(1);
  $(qtyObject).removeClass('error');
  if (qty > 100) {
    $(this).parent().siblings('.cell-quantity').find('.qty-product-field').addClass('error');
    setTimeout(function () {
      $('.qty-product-field.error').removeClass('error');
    }, 3000);
  }

  $.ajax({
    type: 'POST',
    headers: {"cache-control": "no-cache"},
    url: actionURL,
    async: true,
    cache: false,
    data: {
      ajax: 1,
      add: 1,
      action: 'update',
      id_product: id_product,
      id_customization: 0,
      qty: qty
    },
    dataType: 'json',
    success: function (result) {
      if (result.success) {
        var refreshURL = $('.blockcart').data('refresh-url');
        var requestData = {};
        requestData = {
          id_product_attribute: result.id_product_attribute,
          id_product: result.id_product,
          action: 'add-to-cart'
        };

        $.ajax({
          type: 'POST',
          headers: {"cache-control": "no-cache"},
          url: refreshURL,
          async: true,
          cache: false,
          data: requestData,
          dataType: 'json',
          success: function (resp) {
            $('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
            if (resp.modal) {
              showModalPopupCart(resp.modal);
            }
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.log("TECHNICAL ERROR: \n\nDetails:\nError thrown: " + XMLHttpRequest + "\n" + 'Text status: ' + textStatus);
          }
        });
      } else {
      }
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      // alert("TECHNICAL ERROR: \n\nDetails:\nError thrown: " + XMLHttpRequest + "\n" + 'Text status: ' + textStatus);
    }
  });
});

function showModalPopupCart(modal) {
  var body = $('body');
  var cartBlock = '#blockcart-modal';

  if ($('.modal-backdrop').length) {
    $('.modal-backdrop').remove();
  }

  if($(cartBlock).length)
    $(cartBlock).remove();

  body.append(modal);
  $(cartBlock).modal('show');
}