import {BodyScroll} from './utils';

export const HeaderTop = {
  isOpened: false,
  el: document.querySelector('.header-top'),
  onClose: undefined,

  show() {
    if (!this.el || this.isOpened) return;

    BodyScroll.lock();

    this.el.classList.add('is-active');
    this.el.classList.add('in');
    this.isOpened = true;
  },

  hide() {
    if (!this.el || !this.isOpened) return;

    BodyScroll.unlock();

    this.el.addEventListener('transitionend', () => {
      this.el.classList.remove('is-active');

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    }, {once: true});

    this.el.classList.remove('in');
    this.isOpened = false;
  }
};

const openHeaderTop = document.querySelector('.js-open-header-top');
const closeHeaderTop = document.querySelector('.js-close-header-top');

if (openHeaderTop) {
  openHeaderTop.addEventListener('click', (event) => {
    event.preventDefault();
    HeaderTop.show();
  });
}

if (closeHeaderTop) {
  closeHeaderTop.addEventListener('click', (event) => {
    event.preventDefault();
    HeaderTop.hide();
  })
}