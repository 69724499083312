import Swiper from 'swiper';

const galleryThumbs = new Swiper('.gallery-thumbs', {
  freeMode: true,
  spaceBetween: 30,
  centeredSlides: true,
  slidesPerView: 'auto',
  touchRatio: 0.2,
  allowTouchMove: false,
  slideToClickedSlide: true,
  breakpoints: {
    992: {
      spaceBetween: 15
    }
  },
  controller: {
    control: galleryTop
  }
});

const galleryTop = new Swiper('.gallery-top', {
  effect: 'fade',
  controller: {
    control: galleryThumbs
  },
  thumbs: {
    swiper: galleryThumbs
  }
});