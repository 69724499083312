import {BodyScroll} from './utils';

export const FilterBoxBody = {
  isOpened: false,
  el: document.querySelector('.filter-box-body'),
  onClose: undefined,

  show() {
    if (!this.el || this.isOpened) return;

    BodyScroll.lock();

    this.el.classList.add('is-active');
    this.el.classList.add('in');
    this.isOpened = true;
  },

  hide() {
    if (!this.el || !this.isOpened) return;

    BodyScroll.unlock();

    this.el.addEventListener('transitionend', () => {
      this.el.classList.remove('is-active');

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    }, {once: true});

    this.el.classList.remove('in');
    this.isOpened = false;
  },

  reloadThisEvent() {
    const openFilter = document.querySelector('.js-open-filter-btn');
    const closeFilter = document.querySelector('.js-close-filter-btn');

    if (openFilter) {
      openFilter.addEventListener('click', (event) => {
        event.preventDefault();
        FilterBoxBody.show();
      });
    }

    if (closeFilter) {
      closeFilter.addEventListener('click', (event) => {
        event.preventDefault();
        FilterBoxBody.hide();
      });
    }
  }
};

FilterBoxBody.reloadThisEvent();

prestashop.on('updateFacets', () => {
  setTimeout(() => {
    FilterBoxBody.reloadThisEvent();
  }, 1000);
});