import {BodyScroll} from './utils';
import {HeaderTop} from './header-top';
import {FilterBoxBody} from './filter';
import {closeSearch} from './search';

/* When click inside of parent region */
document.addEventListener('click', (event) => {

});

/* Key down Esc */
document.addEventListener('keydown', (e) => {
  e = e || window.event;

  if (e.keyCode === 27) {
    BodyScroll.flush();

    HeaderTop.hide();
    FilterBoxBody.hide();

    closeSearch();
  }
});

/* Resize */
window.addEventListener('resize', () => {
  BodyScroll.unlock();

  HeaderTop.hide();
  FilterBoxBody.hide();
});