let goToTop = document.querySelector('.go-to-top');
let body = document.documentElement;

window.addEventListener('scroll', check);

function check() {
  const vw = window.innerWidth;

  if (vw >= 992 && goToTop) {
    pageYOffset >= 500 && goToTop.classList.add('is-visible');
    pageYOffset < 500 && goToTop.classList.remove('is-visible');
  }
}

if (goToTop) {
  goToTop.onclick = function () {
    animate({
      duration: 700,
      timing: goToTopEaseOut,
      draw: progress =>
        body.scrollTop = (body.scrollTop * (1 - progress / 7))
    });
  };
}

let circle = timeFraction =>
  1 - Math.sin(Math.acos(timeFraction > 1 ? timeFraction = 1 : timeFraction));

let makeEaseOut = timing => timeFraction => 1 - timing(1 - timeFraction);
let goToTopEaseOut = makeEaseOut(circle);

function animate(options) {
  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / options.duration;
    timeFraction > 1 && (timeFraction = 1);

    let progress = options.timing(timeFraction);

    options.draw(progress);
    timeFraction < 1 && requestAnimationFrame(animate);
  });
}