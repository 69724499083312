$('.quantity-btn').on("click", function () {

  const $button = $(this);

  let oldValue = $button.siblings('.qty-product-field').val();
  let newVal;

  if ($button.hasClass('inc')) {
    newVal = parseFloat(oldValue) + 1;
  } else {
    if (oldValue > 1) {
      newVal = parseFloat(oldValue) - 1;
    } else {
      newVal = 1;
    }
  }

  $button.siblings('.qty-product-field').val(newVal);
});

let qtyProductField = document.querySelector('.qty-product-field');

if(qtyProductField) {
  qtyProductField.addEventListener('keyup', function(){
    this.value = (parseInt(this.value) < 1 || parseInt(this.value) > 99 || isNaN(this.value)) ? '1' : (this.value)
  });
}